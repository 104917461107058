import axios from "axios";
import { appTransportService } from "../../presentation/services/app_transport_service";
import { EnvironmentConstants } from "../../constants/environment";

function createClient() {
    const client = axios.create({
        baseURL: EnvironmentConstants.apiUrl,
        headers: {
            Device: "Web",
            "Content-Type": "application/json;charset=UTF-8",
            accept: "application/json",
        },
        withCredentials: true,
        timeout: 10000,
    });

    client.interceptors.request.use((request) => {
        const requestLog = `request: ${JSON.stringify(request, null, 2)}`;
        appTransportService.log(requestLog);
        return request;
    });

    client.interceptors.response.use(
        (response) => {
            const responseLog = `response: ${JSON.stringify(
                response,
                null,
                2
            )}`;
            appTransportService.log(responseLog);
            return response;
        },
        (error) => {
            const errorLog = `error: ${JSON.stringify(
                error.response,
                null,
                2
            )}`;
            appTransportService.log(errorLog);
            return Promise.reject(error);
        }
    );

    return client;
}

const client = createClient();

export default class BaseRepository {
    client = client;
}
