import React from "react";
import { useEffect, useRef, useState } from "react";
import {
    BootOption,
    channelTalkService,
} from "../../services/channel_talk_service";
import { ChannelConstants } from "../../../constants/channel";
import { appTransportService } from "../../services/app_transport_service";
import { channelTalkRepository } from "../../../data/repositories/channel_talk_repository";
import MainGraphicsImage from "./views/main_graphics_image";
import MainDescription from "./views/main_description";
import MainClickButtonMessage from "./views/main_click_button_message";
import {
    JrcAppPage,
    JrcPageStatusType,
} from "@jeekim-company/jeekim-react-core";
import { LawChatUser } from "../../../domain/law_chat/law_chat_user";

export default function MainPage() {
    const [pageStatus, setPageStatus] = useState(JrcPageStatusType.Loading);
    const [showChannelTalkArrow, setShowChannelTalkArrow] = useState(false);
    const [lawChatUser, setLawChatUser] = useState<LawChatUser | undefined>(
        undefined
    );
    const userIdRef = useRef(undefined);
    const chatIdRef = useRef(undefined);

    useEffect(() => {
        const setUpChannelTalk = (lawChatUser: LawChatUser) => {
            channelTalkService.loadScript();

            const bootOption: BootOption = {
                pluginKey: ChannelConstants.pluginKey,
                memberId: lawChatUser.id,
            };

            channelTalkService.boot(bootOption, () => {
                channelTalkService.showMessenger();
                channelTalkService.openChat(chatIdRef.current);

                setShowChannelTalkArrow(true);
            });
        };

        const setUpJavascriptBridge = () => {
            window.onInit = async (serializedString: string) => {
                const lawChatUser = JSON.parse(serializedString) as LawChatUser;

                userIdRef.current = await channelTalkRepository.upsertUser({
                    memberId: lawChatUser.id,
                    username: lawChatUser.name,
                    phoneNumber: lawChatUser.phoneNumber,
                });

                chatIdRef.current =
                    await channelTalkRepository.getMostRecentUserChatId({
                        userId: userIdRef.current!,
                    });

                setLawChatUser(lawChatUser);
                setPageStatus(JrcPageStatusType.Loaded);

                await new Promise((resolve) => setTimeout(resolve, 2000));

                setUpChannelTalk(lawChatUser);
            };

            appTransportService.onWebInit();
        };

        setUpJavascriptBridge();
    }, []);

    return (
        <JrcAppPage pageStatus={pageStatus}>
            <MainGraphicsImage />
            <MainDescription name={lawChatUser?.name} />
            <MainClickButtonMessage visible={showChannelTalkArrow} />
        </JrcAppPage>
    );
}
