export const S = {
    common: {
        unknownErrorMessage: "알 수 없는 에러가 발생했습니다.",
    },

    errorPage: {
        errorOccured: "에러가 발생했습니다",
    },

    mainPage: {
        lawChatServiceTitle: "변호사와 채팅으로 궁금증을 해결하세요",
        lawChatServiceDescription: ({ name }: { name: string }) =>
            `${name}님은 매달 1회 상담이 제공됩니다.`,
        lawChatServiceDescriptionWithNoName: "매달 1회 상담이 제공됩니다.",
        pressToStart: "눌러서 시작하기 >>",
    },
};
