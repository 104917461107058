import React, { ReactElement } from "react";
import { AppRoutes } from "./app_routes";
import { AppRouterPage } from "./app_router_page";
import { JrcAppPage, JrcErrorView } from "@jeekim-company/jeekim-react-core";
import MainPage from "../pages/main/main_page";

const ErrorPage = () => (
    <JrcAppPage>
        <JrcErrorView />
    </JrcAppPage>
);

class ErrorRouteMap {
    toObject() {
        return {
            path: "*",
            element: <ErrorPage />,
            errorElement: <ErrorPage />,
        };
    }
}

class RouteMap {
    route: AppRoutes;
    component: ReactElement;

    constructor(route: AppRoutes, component: ReactElement) {
        this.route = route;
        this.component = component;
    }

    toObject(errorPage: ReactElement | undefined = undefined): Object {
        return {
            path: this.route,
            element: <AppRouterPage>{this.component}</AppRouterPage>,
            errorElement: errorPage || <ErrorPage />,
        };
    }
}

export const appRouteMapping = [
    new RouteMap(AppRoutes.Main, <MainPage />),
    new ErrorRouteMap(),
].map((route) => route.toObject());
