import React from "react";
import styled from "styled-components";
import { Palette } from "../../../theme/palette";
import { Assets } from "../../../theme/assets";

const MainGraphicsImageContainer = styled.div`
    width: 100%;
    height: 50lvh;
    height: 50dvh;
    height: 50vh;
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: center;
`;

const ImageContainer = styled.img`
    width: 213px;
    background-color: ${Palette.white100};
`;

export default function MainGraphicsImage() {
    return (
        <MainGraphicsImageContainer>
            <ImageContainer src={Assets.lawChatGraphics} />
        </MainGraphicsImageContainer>
    );
}
