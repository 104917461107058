import React, { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { RecoilRoot } from "recoil";
import {
    GlobalTheme,
    animationTheme,
    appBarTheme,
    errorTheme,
    fontTheme,
    pageTheme,
} from "./theme/theme";
import { AppRouter } from "./routes/app_router";
import {
    JrcDialog,
    JrcErrorBoundary,
    JrcThemeProvider,
} from "@jeekim-company/jeekim-react-core";
import { Assets } from "./theme/assets";
import { preloadImages } from "./utils/functions/preload_image";

export const App = () => {
    useEffect(() => {
        preloadImages(Object.values(Assets));
    }, []);

    return (
        <RecoilRoot>
            <GlobalTheme />
            <JrcThemeProvider
                appBarTheme={appBarTheme}
                pageTheme={pageTheme}
                animationTheme={animationTheme}
                errorTheme={errorTheme}
                fontTheme={fontTheme}
            >
                <JrcErrorBoundary>
                    <JrcDialog>
                        <RouterProvider router={AppRouter} />
                    </JrcDialog>
                </JrcErrorBoundary>
            </JrcThemeProvider>
        </RecoilRoot>
    );
};
