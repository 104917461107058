import React from "react";
import styled from "styled-components";
import { Fonts } from "../../../theme/fonts";
import { S } from "../../../theme/s";
import { JrcFontWeights } from "@jeekim-company/jeekim-react-core";

const MessageContainer = styled.span<{ $visible: boolean }>`
    visibility: ${(props) => (props.$visible ? "visible" : "hidden")};
    position: fixed;
    bottom: 28px;
    right: 65px;
    ${Fonts.body1}
    font-size: 1rem;
    font-weight: ${JrcFontWeights.bold};
`;

export default function MainClickButtonMessage({
    visible,
}: {
    visible: boolean;
}) {
    return (
        <MessageContainer $visible={visible}>
            {S.mainPage.pressToStart}
        </MessageContainer>
    );
}
