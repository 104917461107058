import { EnvironmentConstants } from "../../constants/environment";

declare const LawChatWebChannel: {
    postMessage: (message: string) => void;
};

class AppTransportService {
    log(message: string) {
        if (!EnvironmentConstants.isDevelopment) return;

        try {
            LawChatWebChannel.postMessage(
                JSON.stringify({
                    type: "log",
                    data: message,
                })
            );
        } catch (_) {}
    }

    onWebInit() {
        try {
            LawChatWebChannel.postMessage(
                JSON.stringify({
                    type: "onInit",
                })
            );
        } catch (_) {}
    }
}

export const appTransportService = new AppTransportService();
