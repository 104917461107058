import React from "react";
import styled from "styled-components";
import { Fonts } from "../../../theme/fonts";
import { S } from "../../../theme/s";
import { JrcFontWeights } from "@jeekim-company/jeekim-react-core";

const MainDescriptionContainer = styled.div`
    width: calc(100% - 90px);
    margin: 20px 45px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
`;

const TitleContainer = styled.span`
    ${Fonts.headline5}
    font-size: 1.5rem;
    font-weight: ${JrcFontWeights.bold};
    text-align: center;
    word-break: keep-all;
`;

const DescriptionContainer = styled.span`
    ${Fonts.body1}
    font-size: 1rem;
    text-align: center;
    word-break: keep-all;
`;

export default function MainDescription({ name }: { name?: string }) {
    return (
        <MainDescriptionContainer>
            <TitleContainer>{S.mainPage.lawChatServiceTitle}</TitleContainer>
            <DescriptionContainer>
                {!!name
                    ? S.mainPage.lawChatServiceDescription({ name: name })
                    : S.mainPage.lawChatServiceDescriptionWithNoName}
            </DescriptionContainer>
        </MainDescriptionContainer>
    );
}
