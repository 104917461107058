import BaseRepository from "./base_repository";

class ChannelTalkRepository extends BaseRepository {
    async upsertUser({
        memberId,
        username,
        phoneNumber,
    }: {
        memberId: string;
        username: string;
        phoneNumber: string;
    }) {
        try {
            const internationalPhoneNumber = phoneNumber.replace("0", "+82");

            const response = await this.client.put(
                `/channel-talk/${memberId}`,
                {
                    profile: {
                        name: username,
                        mobileNumber: internationalPhoneNumber,
                    },
                    unsubscribeEmail: true,
                    unsubscribeTexting: true,
                }
            );

            return response.data.user.id;
        } catch (_) {}
    }

    async getMostRecentUserChatId({ userId }: { userId: string }) {
        try {
            const response = await this.client.get(
                `/channel-talk/${userId}/user-chats`,
                {
                    params: {
                        sortOrder: "desc",
                    },
                }
            );

            const chatList = response.data.userChats;

            if (chatList.length > 0) {
                return chatList[0].id;
            }

            return undefined;
        } catch (_) {}
    }
}

export const channelTalkRepository = new ChannelTalkRepository();
